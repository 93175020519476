<template>
  <div class="about">
    <Sidebar class="sidebar" />
    <div class="about-content">
      <div class="about-section" id="licenses">

        <h1>Licenses</h1>

        <p>
          Artwork tokens open up many questions about what it means to "own" or "hold" a
          tokenized artwork.  Does the token convey some special right to the holder?  Certain
          rights are likely implicit, and solidly established by conventional usage.  These
          rights likely include the right to brag about one's ownership of a token, to display
          it's likeness on a personal page or social media post, etc.  But what else?
        </p>
        <p>
          To help answer this question, we make a habit of including an explicit "holder
          license" in the metadata of all tokens issued through ArtCASA, alongside a more
          general (and perhaps more restricted) license applicable to holders and non-holders
          alike.  The well-known and established Creative Commons licenses are great candidates
          for either or both of these license fields.  But to address some of the unique
          concerns of tokenized art, we propose some alternatives here.
        </p>

      </div>
      <div class="about-section" id="ac-thc-1.0">

        <h1>ArtCASA Token Holder's Commercial License 1.0</h1>
        <h2>(A work in progress...)</h2>

        <p style="text-align: center;"><em>
          This license is a work in progress, and its content and terms should be considered
          in-flux and subject to change until such time as this notice is removed.
        </em></p>
        <p>
          The purpose of this license is to extend to the token holder certain commercial
          licensee rights, similar in nature to the Creative Commons CC-4.0 license, unburdened
          by the Non-Commercial (NC) restriction.  This license may optionally preserve the
          Attribution (BY), No Derivatives (ND), or Share-Alike (SA) restrictions, or may be
          unencumbered by those restrictions.
        </p>
        <h3>Nomenclature:</h3>
        <p>
          This license may be denoted AC-THC-[restrictions]-[version].  For example,
          AC-THC-BY-1.0 means the "ArtCASA Token Holder's Commercial License 1.0 with
          Attribution (BY) restriction."  It implies the token holder may use the work
          commercially, and make derivative works, provided credit is given to the original
          artist in both cases.
        </p>
        <h3>License details:</h3>
        <p>
          The ArtCASA Token-Holder's Commercial License extends to the holder of the licensed
          token rights that go beyond that of the general license, if any, affixed to the token,
          and conveys these rights only to the token holder(s), and only during such time as
          they shall hold the token.
        </p>
        <p>
          COMMERCIAL USE: The holder of the token may use the licensed work (art, music, or
          other content so licensed) for commercial purposes. This may include publishing the
          work in a for-profit publication or other content delivery service, whether in print
          or electronic, or for commercial marketing or promotional services, or any other
          for-profit medium or activity.  Note that extending this right to token holders does
          not preclude the right of "public service" display of the content, such as by a
          blockchain viewer, token explorer, or gallery that allows users to inspect the tokens
          as objects of general interest.
        </p>
        <p>
          The token holder must hold the token at the time the work is used commercially, and
          need not hold the token in perpetuity when the usage is complete.  In the case of
          "durable" publications, such as an article published to an online news outlet, the
          token must be held at the time publication is made, but need not be held for the
          duration of which the article is hosted and retrievable online.
        </p>
        <h2>Examples:</h2>
        <h3>An orchestral arrangement:</h3>
        <p>
          Let's say a composer releases a tokenized orchestral score as a tokenized work, with
          an issuance of eight. This means eight tokens circulate.  Now let's say the
          world-famous Enneftee Symphony Orchestra wishes to perform the piece live to a
          ticketted audience.  The orchestra would need to acquire at minimum ONE of the
          circulating tokens, as each token fully conveys the licensed rights, and hold it
          throughout the period over which the piece is performed.  When the orchestra is no
          longer performing the piece to ticketted audiences, it me resell the token to another
          party.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
export default {
  name: "Licenses",
  components: {
    Sidebar
  }
};
</script>

<style scoped>
@media (max-width: 768px) {
  .sidebar {
    display: none;
  }
}
.about {
  display: flex;
  width: 100%;
}
.about-content {
  background-color: rgb(104, 152, 64);
  color: var(--title-text);
  border-radius: 25px;
  min-width: 30ch;
  max-width: 800px;
  padding: 0.75em;
  margin-right: auto;
}
.about-section {
  /* background-color: rgb(218, 230, 208); */
  background-color: rgb(238, 243, 232);
  border-radius: 16px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 0.5em 1em;
  margin-bottom: 1em;
  font-family: "Open Sans", Helvetica, sans-serif;
}
.about-section a {
  font-weight: bold;
}
.about-section .figure {
  margin: 0 auto 1em auto;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.about-section p {
  text-align: left;
}
.about-section h2 {
  text-align: center;
}
.about-section h3 {
  text-align: left;
}
.about-section table {
  background-color: rgb(252, 168, 40);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin: 0 auto;
}
.about-section tr {
  background-color: rgba(255, 255, 255, 0.6);
}
.about-section td {
  padding-left: 1ch;
  padding-right: 1ch;
}
.about-section ul {
  text-align: left;
}
</style>
