// nftParse.js

/**
 * Takes in an asset object and returns an onject with NFT-relevant keys and values. If ddo
 * (dynamic data object) is not none, then returns additional keys.
 */
function parseNftObject(assObj, ddo = null) {
  var ret = {};
  // Asset basic properties:
  ret.nftAssetID = assObj.id;
  ret.nftAsset = assObj.symbol;
  ret.max_supply = assObj["options"]["max_supply"];
  // Dynamic properties:
  if (ddo !== null) {
    ret.supply = ddo["current_supply"];
  }
  const descObj = JSON.parse(assObj["options"]["description"]);
  const nobj = descObj["nft_object"];
  // Description properties:
  ret.market = descObj["market"];
  ret.signature = descObj["nft_signature"];
  // NFT Object properties
  for (var key of [
    "title", "artist", "narrative", "attestation", "license", "holder_license",
    "tags", "flags", "acknowledgments", "sig_pubkey_or_address"
  ]) {
    ret[key] = nobj[key];
  }
  // Media data:
  for (const key of ["image_png", "image_PNG", "media_png", "media_PNG",
                     "image_gif", "image_GIF", "media_gif", "media_GIF"]) {
    if (nobj[key] != undefined) {
      ret.base64_img = nobj[key];
    }
  }
  for (const key of ["media_png_multihash", "media_PNG_multihash",
                     "media_jpg_multihash", "media_JPG_multihash",
                     "media_jpeg_multihash", "media_JPEG_multihash",
                     "media_gif_multihash", "media_GIF_multihash"]) {
    if (nobj[key] != undefined) {
      if (typeof nobj[key] == 'string') {
        const mmh = nobj[key];
        if (mmh.startsWith("/ipfs/")) {
          const parts = mmh.split("/");
          if (parts[2]) ret.ipfs_pin = parts[2];
          ret.media_url = "https://ipfs.artcasa.gallery" + mmh;
          break;
        }
      } else if (typeof nobj[key] == 'object') {
        console.log("NFTEA stuff here");
        const mmho = nobj[key];
        for (const key2 in mmho) {
          if (key2.endsWith(".png")) {
            ret.media_url = "https://ipfs.artcasa.gallery/ipfs/" + mmho["cid"] + "/" + key2;
          }
        }
      }
    }
  }
  // Supplemental links:
  ret.supplemental_links = [];
  if (nobj["supplemental_links_ipfs"] instanceof Array) {
    for (const item of nobj["supplemental_links_ipfs"]) {
      const link = {
        text: item["text"],
        link: item["link"].startsWith("/ipfs/") ? "https://ipfs.artcasa.gallery" + item["link"] : ""
      };
      ret.supplemental_links.push(link);
    }
  }
  console.log(ret);
  return ret;
}

export { parseNftObject }
