<template>
  <div class="full_asset">
    <h1>{{ title }}</h1>
    <div class="nft-image-region">
      <img v-if="media_url.length > 0" class="nftViewer" :src="media_url" />
      <img v-else class="nftViewer" :src="'data:image/png;base64,' + base64_img" />
    </div>
    <div id="description" class="attr-group">
      <h1>Description:</h1>
      <table style="font-size: 110%">
        <tr>
          <th>Title:</th>
          <td>{{ title }}</td>
        </tr>
        <tr>
          <th>Artist:</th>
          <td>{{ artist }}</td>
        </tr>
        <tr>
          <th><span style="white-space: nowrap;">Artist's signing key:</span></th>
          <td class="key">{{ sig_pubkey_or_address }}</td>
        </tr>
        <tr>
          <th>Artist's signature:</th>
          <td class="key wrapword">{{ signature }}</td>
        </tr>

      </table>
      <h2>Narrative / Description:</h2>
      <div style="text-align: center;">
        <div class="narrative-backdrop q-before">&ldquo;</div>
        <div class="narrative-backdrop" style="display: inline-block; text-align: left;">
          <p style="font-size: 140%; font-family: serif; font-style: italic;"
             v-html="narrative_processed">
          </p>
        </div>
        <div class="narrative-backdrop q-after">&rdquo;</div>
      </div>
      <div v-if="acknowledgments">
        <h2>Acknowledgments:</h2>
        <p>{{ acknowledgments }}</p>
      </div>
      <div v-if="ipfs_pin">
        <h2>Image Data and Preservation:</h2>
        <p>
          The image data for this artwork is hosted in the Interplanetary
          File System (IPFS). You can
          <em><a :href="'https://ipfs.artcasa.gallery/ipfs/'+ipfs_pin">
            browse the data here
          </a></em>, or you can help preserve this artwork by pinning
          this hash to your personal IPFS node:
          <pre>{{ ipfs_pin }}</pre>
        </p>
      </div>
    </div>
    <div class="attr-group">
      <h1>Token Attributes:</h1>
      <table>
        <caption></caption>
        <tr>
          <th>Symbol:</th>
          <td>{{ nftAsset }}</td>
        </tr>
        <tr>
          <th>Asset ID:</th>
          <td>{{ nftAssetID }}</td>
        </tr>
        <tr>
          <th>Supply:</th>
          <td>{{ supply }} of {{ max_supply }}</td>
        </tr>
      </table>
      <h2>Current Token Holder(s):</h2>
      <p>
        <span v-if="holder_list.length === 0"><em>{{ holder_none_word }}</em></span>
        <span v-for="(holder, index) in holder_list" v-bind:key=index>
          <span v-if="index > 0">, </span>
          <a :href="'/u/' + holder">{{ holder }}</a>
        </span>
      </p>
    </div>
    <div class="attr-group">
      <h1>Legal:</h1>
      <h2>Attestation:</h2>
      <p>"{{ attestation }}"</p>
      <h2>License:</h2>
      <p>"{{ license }}"</p>
      <h2>Holder License:</h2>
      <p>"{{ holder_license }}"</p>
    </div>
    <div class="attr-group">
      <h1>Extra:</h1>
      <h2>Tags:</h2>
      <p>{{ tags }}</p>
      <h2>Flags:</h2>
      <p>{{ flags }}</p>
      <div v-if="supplemental_links.length > 0" id="supplemental">
          <h2>Supplemental Links:</h2>
          <p v-for="(item, index) in supplemental_links" v-bind:key=index><a :href="item.link">{{ item.text }}</a></p>
      </div>
    </div>
    <div class="attr-group">
      <h1>Market:</h1>
      <div style="width: 95%; margin: 1em auto;">
        <MarketWidget v-if="market"
                      :nftAssetObj="nftAssetObj" :cashAssetSym="market"
                      :bid_limit="8" :ask_limit="8"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { parseNftObject } from "@/components/nftParse.js";
import MarketWidget from "./MarketWidget";
 export default {
  name: "FullAsset",
  props: {
    asset: Object,
    asset_ddo: Object,
    holders: Array
  },
  components: {
    MarketWidget
  },
  created() {
    this.setup();
  },
  data() {
    return {
      title: "Title",
      artist: "Artist",
      base64_img: "",
      media_url: "",
      ipfs_pin: "",
      nftAssetID: "1.3.x",
      nftAsset: "SYMBOL",
      nftAssetObj: {},
      market: "",
      narrative: "Narrative",
      attestation: "Attestation",
      license: "Not included",
      holder_license: "Not included",
      tags: "",
      flags: "",
      acknowledgments: "",
      sig_pubkey_or_address: "",
      signature: "Signature",
      supply: 0,
      max_supply: 0,
      holder_list: [],
      holder_none_word: "Searching...",
      supplemental_links: []
    };
  },
  computed: {
    narrative_processed() {
      return this.narrative
                 .replaceAll("\n\n", "<p>")
                 .replaceAll("\n", "<br>");
    }
  },
  methods: {
    async setup() {
      this.nftAssetObj = this.asset;
      const parsed = parseNftObject(this.nftAssetObj, this.asset_ddo);
      for (const key in parsed) {
        if (key in this) {
          this[key] = parsed[key];
        }
      }
      this.holder_list = this.holders;
      this.holder_none_word = "None";
      //// check for background color prefs
      //if (obj.nft_object.displayprefs_media != undefined) {
      //  var bg_color = obj.nft_object.displayprefs_media["background-color"];
      //  var e = document.getElementsByClassName("nftViewer");
      //  for (var i = 0; i < e.length; i++) {
      //    e[i].style.backgroundColor = bg_color;
      //  }
      //}
    }
  }
};
</script>

<style scoped>
@media (max-width: 768px) {
  .nftViewer {
    max-width: 75%;
  }
}
.full_asset {
  width: auto;
  max-width: 800px;
  border: solid black;
  border-width: 2px;
  border-radius: 10px;
  overflow: hidden;
  /*background-image: var(--background-color);*/
  background-image: linear-gradient(#fffff0, #fff0c0);
  padding: 0;
  margin: 0 0em;
}
.nft-image-region {
  padding: 0.75em;
  background-image: var(--background-color);
}
.nftViewer {
  object-fit: cover;
  object-position: bottom;
  max-width: 95%;
}
h1 {
  background-color: tan;
  padding: 2px;
  margin: 0;
  font-size: 36px;
  /* border-style: solid;
  border-width: 3px;
  border-color: transparent;
  border-radius-top: 10px;
  background-color: var(--text-bgd); */
  color: var(--title-text);
}
p {
  color: var(--title-text);
  margin: 0.125em;
}
table {
  color: var(--title-text);
  width: auto;
  min-width: 60%;
  max-width: 95%;
  margin: 1em auto;
  border-spacing: 0 4px;
}
th {
  /*background-image: linear-gradient(#fffff0, #fff0c0);*/
  border-radius: 10px;
  text-align: left;
  font-style: italic;
  margin: 0;
  padding-right: 1em;
}
td {
  border-radius: 8px;
  text-align: right;
  padding-left: 1em;
  padding-right: 0.5em;
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: rgba(64, 0, 0, 0.05);
}
#description td {
  text-align: center;
}
td.key {
  font-family: monospace;
  font-size: 110%;
}
td.wrapword { /* https://stackoverflow.com/a/5108367 */
    white-space: -moz-pre-wrap !important;  /* Mozilla, since 1999 */
    white-space: -webkit-pre-wrap;          /* Chrome & Safari */ 
    white-space: -pre-wrap;                 /* Opera 4-6 */
    white-space: -o-pre-wrap;               /* Opera 7 */
    white-space: pre-wrap;                  /* CSS3 */
    word-wrap: break-word;                  /* Internet Explorer 5.5+ */
    word-break: break-all;
    white-space: normal;
}
.attr-group {
  width: auto;
  background-color: #fffff0;
  background-image: linear-gradient(#fffff0, #fff0c0);
  border-top: 2px solid brown;
  text-align: left;
}
.attr-group h1 {
  color: black;
  font-size: 26px;
  background-color: tan;
  border-radius: 0;
  padding: 0 10px 4px 10px;
  margin: 0;
}
.attr-group h2 {
  color: black;
  font-style: italic;
  font-size: 20px;
  background-color: rgba(255,224,192,0.5); /*papayawhip; /*blanchedalmond;*/
  border-radius: 0;
  border-top: 2px none steelblue;
  border-bottom: 2px none steelblue;
  padding: 4px 10px;
  margin: 0;
}
.attr-group p {
  color: black;
  padding: 1em 40px;
  margin: 0em 0;
}
.attr-group pre {
  background-color: rgba(64, 0, 0, 0.05);
  font-size: 120%;
  padding: 0.75em 1.5em;
  border-radius: 4px;
}
#supplemental p {
  padding: 0.25em 40px;
}
#supplemental p:nth-child(odd) {
  background-color: rgba(255, 255, 255, 0.4);
}
.attr-group .narrative-backdrop {
  display: inline-block;
  background-color: rgba(255,255,255,0.5);
  border-radius: 8px;
  max-width: 80%;
  margin: 20px 0;
}
.attr-group .narrative-backdrop.q-before {
  display: inline-block;
  background-color: transparent;
  max-width: 5%;
  vertical-align: top;
  margin: 30px 8px auto auto;
  font-family: Times, serif;
  font-style: italic;
  font-size: 60px;
  color: #404040;
}
.attr-group .narrative-backdrop.q-after {
  display: inline-block;
  background-color: transparent;
  max-width: 5%;
  vertical-align: bottom;
  margin: auto auto 0px 8px;
  font-family: Times, serif;
  font-style: italic;
  font-size: 60px;
  color: #404040;
}</style>
