<template>
  <div class="preview">
    <Sidebar class="sidebar" />
    <div class="preview-broadsheet">
      <div class="preview-content">
        <h1>Message Signer</h1>
        <p>
          This widget may be used to digitally sign your NFT object
          blob using your BitShares memo key.  This allows tokenized
          artwork to be authenticated against a public key known to be
          in use by the artist, and prevents copycats and fakes.
        </p>
        <h2>Warning!</h2>
        <p>
          Use this widget with care!  It is provided as a convenience,
          but the responsibility for secure handling of your private
          keys rests with YOU.
        </p>
        <h2>Is this a good idea?</h2>
        <p>
          It's not the <em>best</em> idea, but the message signing
          facility in the BitShares reference wallet insists on
          appending a timestamp to messages before signing them,
          turning the timestamp and other metadata into necessary
          "extra baggage" for validating the signature.  This is fine
          for authenticating messages between humans, but complicates
          the validation of NFT signatures.  Therefore this tool
          exists to serve as a simple, effective, no-frills, ad hoc
          message signer.
        </p>
        <p>
          Precautions: Check the address bar before pasting your
          private key below.  Verify it's the site you think is and
          that the connection shows as secure.  Also not a bad idea to
          turn off WiFi or disconnect from the internet before pasting
          your private key, and to close this window before turning
          WiFi back on again.
        </p>
      </div>
      <div class="preview-content">
        <div class="inputline">
          <p class="inputlabel">Memo private key: (WIF)</p>
          <div class="keyinput"><input type="password" v-model="privkey_wif" @input="reset_sig"/></div>
        </div>
        <div class="inputline">
          <p class="inputlabel">Public Key:</p>
          <p class="keyoutput wordwrap">{{ pubkey58 }}</p>
        </div>
        <p>Message: (Paste NFT object blob here.)</p>
        <textarea id="json_blob" class="ta" v-model="message" @input="reset_sig"/>
        <button @click="preview_click()" :disabled="!readyToSign" class="p-button">
          Sign Message
        </button>
        <p>
          Signature:
        </p>
        <p class="sigout wordwrap" :key="signature">
          {{ signature }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar";
import {Signature, PrivateKey} from "bitsharesjs";
export default {
  name: "MessageSigner",
  components: {
    Sidebar
  },
  data() {
    return {
      privkey_wif: "",
      message: "",
      signature: "N/A"
    };
  },
  computed: {
    pubkey58() {
      if (!this.privkey_wif) {
        return "(Paste private key in box above, pubkey will show here.)";
      } else {
        try {
          const pub = PrivateKey.fromWif(this.privkey_wif).toPublicKey();
          return pub.toString("BTS"); // TODO: pass chain prefix from config
        } catch(e) {
          return "(Please enter valid WIF format.)";
        }
      }
    },
    readyToSign() {
      // TODO: get chain prefix from config
      return (this.message.trim().length > 0) && this.pubkey58.startsWith("BTS");
    }
  },
  methods: {
    reset_sig() {
      this.signature = "N/A";
    },
    preview_click: async function() {
      // Signing is slow, so set a please-wait notice:
      this.signature = "signing...";
      // ...But DOM doesn't update, so use Double-RAF "weird trick" to force update.
      // (Tried this.nextTick() but some Safari/Chrome bug makes it not work.)
      // see https://medium.com/@owencm/one-weird-trick-to-performant-touch-response-animations-with-react-9fe4a0838116
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          var json_string = this.message.trim(); // remove leading/trailing whitespace
          try {
            var pk = PrivateKey.fromWif(this.privkey_wif);
            var sig_obj = Signature.sign(json_string, pk);
            this.signature = sig_obj.toHex();
          } catch(e) {
            console.log(e);
            this.signature = "Error. Check private key. Is it valid WIF format?";
          }
        })
      });
    }
  }
};
</script>

<style scoped>
@media (max-width: 768px) {
  .sidebar {
    display: none;
  }
}
.preview {
  display: flex;
  width: 100%;
}
.ta {
  min-width: 95%;
  min-height: 15em;
  margin: 0;
  margin-right: 5%;
}
.preview-broadsheet {
  background-color: rgb(104, 152, 64);
  color: var(--title-text);
  border-radius: 25px;
  min-width: 30ch;
  max-width: 800px;
  padding: 0.75em;
  margin-right: auto;
}
.preview-content {
  background-color: rgb(240, 248, 244);
  border-radius: 16px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 0.5em 1em;
  margin-bottom: 1em;
  font-family: "Open Sans", Helvetica, sans-serif;
}
.preview-content p {
  text-align: left;
  margin: 0.5em 0;
}
.p-button {
  background-color: var(--btn-bgd);
  color: white;
  padding: 1em 2em;
  cursor: pointer;
  border-radius: 30px;
  outline: none;
  font-weight: 900;
  margin-bottom: 0.25em;
}
.p-button:disabled {
  background-color: darkgray;
  color: lightgray;
  cursor: auto;
}
a {
  color: var(--accent-color);
}
h1 {
  color: var(--title-text);
}
h1 {
  margin-top: 0%;
  border-style: solid;
  border-width: 3px;
  border-color: transparent;
  border-radius: 10px;
  color: var(--title-text);
}
p {
  color: var(--title-text);
  margin: 0.125em;
}
.inputline {
  width: auto;
  text-align: left;
  /*outline: 2px solid green;*/
}
.inputlabel {
  display: inline-block;
  text-align: left;
  margin-right: auto;
  padding-right: 1em;
  /*outline: 2px solid red;*/
}
.keyinput, .keyoutput {
  display: inline-block;
  width: auto;
  margin-right: auto;
  /*outline: 2px solid red;*/
}
.keyinput input {
  width: 35em;
}
p.keyoutput {
  border-radius: 8px;
  font-size: 80%;
  padding: 0.5em 1em;
  background-color: rgba(64, 0, 0, 0.05);
  text-align: left;
  margin: 0;
  margin-left: auto;
}
.sigout {
  min-height: 4.5em;
  border-radius: 8px;
  padding: 0.5em 1em;
  margin: 0.25em 1em;
  background-color: rgba(64, 0, 0, 0.05);
  text-align: left;
}
.wordwrap { /* https://stackoverflow.com/a/5108367 */
  white-space: -moz-pre-wrap !important;  /* Mozilla, since 1999 */
  white-space: -webkit-pre-wrap;          /* Chrome & Safari */
  white-space: -pre-wrap;                 /* Opera 4-6 */
  white-space: -o-pre-wrap;               /* Opera 7 */
  white-space: pre-wrap;                  /* CSS3 */
  word-wrap: break-word;                  /* Internet Explorer 5.5+ */
  word-break: break-all;
  white-space: normal;
}
</style>
