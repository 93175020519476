<template>
  <div class="userpage">
    <Sidebar class="sidebar" />
    <div class="assets">
      <AssetsSheet
        class="assets-trough"
        :assets="valid_assets"
        :header="'From the  Collection of ' + account_name + ':'"
        :account_name="account_name"
        :identicon="identicon"
        :balances="account_balances"
      />
    </div>
  </div>
</template>

<script>
import * as jdenticon from "jdenticon";
import sha256 from "js-sha256";
import AssetsSheet from "@/components/AssetsSheet";
import Sidebar from "@/components/Sidebar";
import { Config } from "@/components/config.js";
export default {
  name: "UserPage",
  components: {
    AssetsSheet,
    Sidebar
  },
  mounted() {
    this.connect();
  },
  data() {
    return {
      valid_assets: null,
      account_name: "",
      account_balances: []
    };
  },
  computed: {
    identicon () {
      return this.account_name ?
             jdenticon.toSvg(sha256(this.account_name), 60) :
             "";
    },
  },
  methods: {
    /**
     * Method: get_account_info()
     *   Gets an accounts name and balances in assets of interest.
     */
    async get_account_info(api, user_id) {
      var accounts_arr = await api.db.get_accounts([user_id], false);
      var account_name = accounts_arr[0]["name"];
      console.log("Looking up user '" + account_name + "'...");
      const tracked = Config.tracked_balances;
      const tracked_ids = tracked.map(a=>a["asset_id"]);
      var tracked_balances = [];
      var assets_arr = await api.db.get_account_balances(user_id, tracked_ids);
      for (const bo of tracked) {
        const amount = assets_arr.filter(a => a.asset_id === bo.asset_id)[0].amount;
        tracked_balances.push({...bo, amount: amount});
      }
      return {
        name: account_name,
        balances: tracked_balances
      };
    },
    /**
     * Method: collect_assets()
     *   Find all NFT assets held by the user in regular balance objects,
     *   market order objects as the asset for sale, and assets tentatively
     *   held or "on loan" in HTLC objects.
     */
    async collect_assets(api, user_id) {

      var accounts_arr = await api.db.get_full_accounts([user_id], false);

      // Find assets held in Balance Objects:
      var balance_object_array = await api.db.get_account_balances(user_id, []);
      balance_object_array = balance_object_array.filter(a => a["amount"] > 0);
      var bo_asset_id_list = balance_object_array.map(a => a["asset_id"]);
      console.log(
        "Found " + bo_asset_id_list.length +
        " assets with non-zero balances: ",
        bo_asset_id_list
      );

      // Limit order for-sale assets:
      var limit_order_array = accounts_arr[0][1]["limit_orders"];
      var lo_asset_id_list = limit_order_array.map(
        a => a["sell_price"]["base"]["asset_id"]
      );
      lo_asset_id_list = lo_asset_id_list.filter( // De-duplicate...
        (item, pos) => lo_asset_id_list.indexOf(item) === pos
      );
      console.log(
        "Found " + lo_asset_id_list.length +
        " assets offered for sale: ",
        lo_asset_id_list
      );

      // Combine lists: (take only uniqe items from second list)
      var asset_id_list = bo_asset_id_list.concat(
        lo_asset_id_list.filter(item => bo_asset_id_list.indexOf(item) < 0)
      );

      // Get full asset objects:
      var house = await api.db.get_assets(asset_id_list, false);
      try {
        var i = 0;
        for (i = 0; i < house.length; i++) {
          console.log(
            "Checking " + house[i]["symbol"] + " for valid JSON description..."
          );
          try {
            JSON.parse(house[i]["options"]["description"]);
          } catch (e) {
            console.log("Caught an e on asset '" + house[i]["symbol"] + "':");
            console.log(e);
            house.splice(i, 1);  // Remove item due to unparsable JSON,
            i--;                 // ...and decrement i, so we don't skip next item.
          }
        }
        // this narrows down to those with an nft_object in description
        var valid_assets_this_house = house.filter(
          asset => JSON.parse(asset["options"]["description"])["nft_object"]
        );
        console.log(
          "Returning " + valid_assets_this_house.length +
          " assets with 'nft_object' fields."
        );
        console.log(valid_assets_this_house.map(a => a["symbol"]));
        return {
          assets: valid_assets_this_house
        };
      } catch (e) {
        console.log("error: ", e);
      }
    },

    async connect() {
      const BitShares = require("btsdex");
      await BitShares.connect(Config.api_node);
      var info = await this.get_account_info(BitShares, this.$route.params.user_id);
      this.account_name = info["name"];
      this.account_balances.push(...info["balances"]);
      var ret = await this.collect_assets(BitShares, this.$route.params.user_id);
      this.valid_assets = ret["assets"];
    }
  }
};
</script>

<style scoped>
@media (max-width: 768px) {
  .sidebar {
    display: none;
  }
}
.userpage {
  display: flex;
  /* flex-wrap: wrap; */
}
.assets {
  /* background-color: white; */
  flex: 1 1 80%;
}
.assets-trough {
  margin-bottom: 1.5em;
}
</style>
