<template>
  <div class="broadsheet">
    <div class="content">
      <div v-if='asset_loaded'>
        <AssetDetail
          :asset='nftAssetObj'
          :asset_ddo='nftAssetDDO'
          :holders='holder_list'
        />
      </div>
      <div v-else>
        Loading asset {{ asset }}...
      </div>
    </div>
  </div>
</template>

<script>
import { Config } from "./config.js";
import AssetDetail from "@/components/AssetDetail";
export default {
  name: "FullAsset",
  props: {
    asset: String
  },
  components: {
    AssetDetail
  },
  created() {
    this.setup();
  },
  data() {
    return {
      nftAssetObj: null,
      nftAssetDDO: null,
      holder_list: [],
      holder_list_loaded: false
    };
  },
  computed: {
    asset_loaded() {
      return this.holder_list_loaded;
    }
  },
  methods: {
    async setup() {
      const BitShares = require("btsdex");
      await BitShares.connect(Config.api_node);
      var ass_get = await BitShares.db.get_objects([this.asset]);
      var asset_obj = ass_get[0];
      this.nftAssetObj = asset_obj;
      var dyn_data_get = await BitShares.db.get_objects([asset_obj["dynamic_asset_data_id"]]);
      var dyn_data = dyn_data_get[0];
      this.nftAssetDDO = dyn_data;
      this.holder_list = await this.get_nft_holders(BitShares, this.nftAssetObj);
      this.holder_list_loaded = true;
    },
    async get_nft_holders(api, asset_obj) {
      const symbol = asset_obj.symbol;
      const id = asset_obj.id;
      const descObj = JSON.parse(asset_obj["options"]["description"]);
      const market = descObj["market"];
      // Asset holders: regular balance holders:
      var holders = [];
      var gah_resp = await api.asset.get_asset_holders(symbol, 0, 50);
      holders = holders.concat(gah_resp.map(a => a["name"]));
      // Asset holders: from market order book
      var limit_orders = await api.db.get_limit_orders(symbol, market, 50);
      limit_orders = limit_orders.filter( // Get only sellers, not bidders:
        a => a["sell_price"]["base"]["asset_id"] === id
      );
      var seller_id_list = limit_orders.map(a => a["seller"]);
      seller_id_list = seller_id_list.filter( // de-duplicate...
        (item, pos) => seller_id_list.indexOf(item) === pos
      );
      var seller_list = await api.db.get_objects(seller_id_list);
      seller_list = seller_list.map(a => a["name"]);
      // Combine lists, make sure seller list contributes only unique names:
      holders = holders.concat(
        seller_list.filter(item => holders.indexOf(item) < 0)
      );
      // TODO: Is it possible to get HTLC's by asset?
      return holders;
    }
  }
};
</script>

<style scoped>
.broadsheet {
  background-color: rgb(104, 152, 64);
  color: var(--title-text);
  border-radius: 25px;
  min-width: 30ch;
  max-width: 800px;
  padding: 0.75em;
  margin-right: auto;
}
.content {
  background-color: rgb(248, 240, 254);
  border-radius: 16px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 0.5em 1em;
  margin-bottom: 1em;
  font-family: "Open Sans", Helvetica, sans-serif;
}
</style>
