<template>
  <div class="widget-base">
    <div class="widget-header">
      Market: {{ nftAssetSym }}:{{ cashAssetSym }}
    </div>
    <div class="list-item ask" v-for="(ask, index) in ask_list" :key="index">
      <b>Buy-now price:</b> {{ ask }} {{ cashAssetSym }}
    </div>
    <div v-if="numBids">
      <div class="list-item bid" v-for="(bid, index) in bid_list" :key="index">
        <b>Top bid:</b> {{ bid }} {{ cashAssetSym }}
      </div>
    </div>
    <div v-else>
      <div class="bid-search">
        {{ bidSearchStatus }}
      </div>
    </div>
    <button @click="makeOffer()" class="makeOfferButton">
      Make offer on BitShares DEX
    </button>
  </div>
</template>

<script>
import { Config } from "./config.js";
export default {
  name: "MarketWidget",
  props: {
    nftAssetObj: Object,
    cashAssetSym: String,
    bid_limit: {default: 3, type: Number},
    ask_limit: {default: 1, type: Number}
  },
  computed: {
    nftAssetSym() {
      return this.nftAssetObj["symbol"];
    },
    nftAssetId() {
      return this.nftAssetObj["id"];
    },
    nftAssetPrec() {
      return this.nftAssetObj["precision"];
    },
    numOffers() {
      return this.bid_list.length + this.ask_list.length;
    },
    numBids() {
      return this.bid_list.length;
    }
  },
  data() {
    return {
      bid_list: [],
      ask_list: [],
      cashAssetId: "",
      cashAssetPrec: 0,
      bidSearchStatus: "Searching for bids..."
    };
  },
  created() {
    this.setup();
  },
  methods: {
    async setup() {
      const BitShares = require("btsdex");
      await BitShares.connect(Config.api_node);
      // Cash asset:
      const cash_asset_obj_l = await BitShares.db.get_assets([this.cashAssetSym], false);
      this.cashAssetId =  cash_asset_obj_l[0]["id"];
      this.cashAssetPrec =  cash_asset_obj_l[0]["precision"];
      // Bids and asks:
      this.setup_bids_and_asks(BitShares.db, this.nftAssetSym, this.cashAssetSym);
    },
    async setup_bids_and_asks(db, base, quote) {
      const limit_orders = await db.get_limit_orders(base, quote, 10);
      const bids = limit_orders.filter(
        a => a["sell_price"]["base"]["asset_id"] === this.cashAssetId
      );
      const asks = limit_orders.filter(
        a => a["sell_price"]["quote"]["asset_id"] === this.cashAssetId
      );
      const bid_prices = bids.map(a => this.scaled_cash_price(a["sell_price"]));
      const ask_prices = asks.map(a => this.scaled_cash_price(a["sell_price"]));
      ask_prices.sort((a,b)=>a-b);
      bid_prices.sort((a,b)=>b-a);
      this.ask_list = ask_prices.slice(0,this.ask_limit);
      this.bid_list = bid_prices.slice(0,this.bid_limit);
      this.bidSearchStatus = (this.bid_list.length > 0) ?
                             "" : "No bids yet.";
    },
    scaled_cash_price(sell_price) {
      // Return price denominated in CASH asset with proper precision scaling
      const base = sell_price["base"]["amount"];
      const quote = sell_price["quote"]["amount"];
      const cash = (sell_price["base"]["asset_id"]===this.cashAssetId) ? base : quote;
      const nft = (sell_price["base"]["asset_id"]===this.cashAssetId) ? quote : base;
      return (cash / nft) * (10 ** this.nftAssetPrec) / (10 ** this.cashAssetPrec);
    },
    makeOffer: function() {
      window.open(
        Config.markets[0]["url"] +
        this.nftAssetSym +
        "_" +
        this.cashAssetSym
      );
    }

  }
};
</script>

<style scoped>
.widget-base {
  padding: 0.2em 0.3em;
  margin: 0 auto;
  border-radius: 6px;
  background-color: rgba(8, 64, 16, 0.1);
  border: 2px solid #003200;
}
.widget-header {
  color: black;
  margin-left: 0;
  margin-right: auto;
  margin-bottom: 0.35em;
  font-weight: bold;
  text-align: left;
  border-bottom: solid black 1pt;
}
.bid-search {
  color: black;
  margin-left: 0;
  margin-right: auto;
  margin-bottom: 0.25em;
  font-style: italic;
  text-align: center;
}
.list-item {
  border: 1px solid black;
  border-radius: 2px;
  margin: 0.2em 0;
  padding: 0.2em;
}
.list-item.bid {
  background-color: rgba(255,235,200,0.2);
  color: #400000;
  border-color: saddlebrown;
}
.list-item.ask {
  background-color: rgba(255,255,255,0.2);
  color: #003200;
  border-color: darkgreen;
}
.makeOfferButton {
  background-color: var(--btn-bgd);
  color: rgb(250, 252, 254);
  padding: 0.6em 2em;
  cursor: pointer;
  border-radius: 20px;
  border: none;
  border: 2px outset indigo;
  font-weight: 800;
  margin: 0.35em auto;
}
</style>
