// config.js

export const Config = {
  api_node: "wss://api.artcasa.gallery/",
  houses: [
    {
      token: "ARTCASA",
      header: "Ex libris House ARTCASA:",
      header2: "The most wonderful things are here. (Or will be. Stay tuned.)"
    },
    {
      token: "NFTEA",
      header: "Ex libris House NFTEA:",
      header2: "This is a featured third-party gallery. Its main page is at: <a href='https://nftea.gallery/'>https://nftea.gallery</a>."
    }
  ],
  markets: [
    {
      name: "BitShares DEX",
      url: "https://wallet.bitshares.org/#/market/"
    }
  ],
  tracked_balances: [
    {
      asset_id: "1.3.5943",
      symbol: "ARTCASA",
      precision: 5
    }
  ]
}

//export const Config = {
//  api_node: "wss://testnet.dex.trading/",
//  houses: [
//    {
//      token: "MKUU",
//      header: "Ex libris House MKUU:",
//      header2: "lorem ipsum"
//    },
//    {
//      token: "REDBAR",
//      header: "Ex libris House REDBAR:",
//      header2: "lorem ipsum"
//    }
//  ],
//  markets: [
//    {
//      name: "BitShares DEX",
//      url: "https://wallet.bitshares.org/#/market/"
//    }
//  ]
//}
