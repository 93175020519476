<template>
  <div>
    <Header />
    <!--<Banner class="b" />-->
    <router-view class="content" />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header";
// import Banner from "@/components/Banner";
import Footer from "@/components/Footer";

export default {
  name: "App",
  components: {
    Header,
    // Banner,
    Footer
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap");
:root {
  --background-color: linear-gradient(rgb(212, 212, 212), rgb(255, 255, 255));
  --accent-color: rgb(31, 161, 209);
  --text-bgd: rgba(58, 10, 27, 0.7); /*rgba(255, 255, 255, 0.3);*/
  --header-bgd: rgb(58, 10, 27);
  --btn-bgd: rgb(58, 10, 27);
  --title-text: rgb(10, 10, 10);
  --light-text: rgb(207, 207, 207);
  --notext-bgd: rgba(255, 255, 255, 0.1);
}
#app {
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--light-text);
  /* margin: auto; */
}
.b {
  margin: 0px;
  padding: 0px;
}
/* .content {
  max-width: 80%;
  margin: auto;
} */
html {
  background-image: var(--background-color);
  background-attachment: fixed;
}
</style>
