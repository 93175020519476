<template>
  <div class="assets-sheet" :style="identicon_style" >

    <div class="assets-header">
      <div class="bar-left">
        <div v-html="identicon" style="background-color: rgba(0,0,0,0.6); border-radius: 6px;" />
      </div>
      <div class="bar-center">
        <div class="head1">{{ header }}</div>
        <div class="head2"><span v-html="header2"/></div>
      </div>
      <div class="bar-right" v-if="balances.length > 0">
        <div class="balance-label">
          Account balance: ({{ account_name }})
        </div>
        <div class="balance-txt" v-for="bal in balances_parsed" :key="bal">
          {{ bal }}
        </div>
      </div>
    </div>

    <div v-if="assets">
      <div class="assets">
        <div class="asset" :key="asset.id" v-for="asset in assetsSlice">
          <AssetCard :asset="asset" />
        </div>
      </div>
      <div style="display:inline-flex; margin-top:16px" v-if="numPages > 1">
        <button class="button left" @click="pagePrevious">
          &lt;
        </button>
        <div class="button middle-text">
          Page {{ curPage }} of {{ numPages }}
        </div>
        <button class="button right" @click="pageNext">
          &gt;
        </button>
      </div>
      <div class="loading-msg" v-if="assets.length == 0">
        <em>The user has no displayable assets at this time.</em>
      </div>
    </div>
    <div class="loading-msg" v-else>
      <em>Loading...</em>
    </div>
  </div>
</template>

<script>
import AssetCard from "./AssetCard";
export default {
  name: "AssetsSheet",
  props: {
    assets: Array,
    header: String,
    header2: {default: "", type: String},
    account_name: {default: "", type: String},
    per_page: {default: 8, type: Number},
    identicon: {default: "", type: String},
    balances: {default: () => [], type: Array}
  },
  components: {
    AssetCard
  },
  created() {
    this.setup();
  },
  data() {
    return {
      idx_page_start: 0
    };
  },
  computed: {
    numPages() {
      return Math.ceil(this.assets.length / this.per_page);
    },
    curPage() {
      return 1 + Math.floor(this.idx_page_start / this.per_page);
    },
    assetsSlice() {
      console.log("THis is ");
      console.log(this);
      return this.assets.slice(this.idx_page_start,
                               this.idx_page_start + this.per_page);
    },
    identicon_url() {
      return this.identicon ?
             "data:image/svg+xml;charset=utf8,"+encodeURIComponent(this.identicon) :
             "";
    },
    identicon_style() {
      //{'background-image': 'url('+identicon_url+')'}
      return this.identicon ? {
        'background-image':
          'linear-gradient(#555555C0, #DDDDDD40), ' +
          'url(' + this.identicon_url + ')'
      } : {};
    },
    balances_parsed() {
      return this.balances.map(
        a =>
          ( ( a.amount / (10 ** a.precision) )
            .toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') +
            " " + a.symbol
          )
      );
    }
  },
  methods: {
    async setup() {
    },
    pageNext() {
      const newval = this.idxWrap(this.idx_page_start + this.per_page);
      console.log("Updating idx_page_start to ", newval, " from ", this.idx_page_start);
      this.idx_page_start = newval;
    },
    pagePrevious() {
      this.idx_page_start = this.idxWrap(this.idx_page_start - this.per_page);
    },
    idxWrap(idx) {
      const limit = this.numPages * this.per_page;
      return ((idx % limit) + limit) % limit; // handles negative correctly
    }
  }
};
</script>

<style scoped>
.assets-sheet {
  padding-bottom: 2em;
  border-radius: 8px;
  background-color: rgba(16, 32, 64, 0.1);
}
.loading-msg {
  font-size: larger;
  color: black;
  background-color: rgba(255,255,255,0.7);
  border-radius: 10px;
  padding: 2em;
  margin: auto 2em;
}
.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 1.1em;
  border: 0;
  background-color: rgba(2, 4, 16, 0.6);
  color: var(--light-text);
  font-weight: bold;
  font-size: 200%;
  padding: 0 0.2em;
  margin: 0 0.2em;
}
.button.middle-text {
  font-size: 100%;
  width: auto;
  padding-left: 0.3em;
  padding-right: 0.3em;
}
.button.left {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}
.button.right {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}
.assets {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.assets-header {
  display: flex;
  width: auto;
  background-color: #A47D7F; /*#B45D6F; /*#948D8F /*rgba(32, 4, 4, 0.3);*/
  margin-bottom: 1em;
  padding: 0.5em 0.5em;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  text-align: left;
}
.assets-header .bar-left {
  display: inline-flex;
}
.assets-header .bar-center {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 8px;
}
.assets-header .bar-right {
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  margin-left: auto;
  padding-right: 8px;
}
.assets-header .head1 {
  display: block;
  text-align: left;
  color: var(--header-bgd);
  font-weight: bold;
  font-size: larger;
}
.assets-header .head2 {
  display: block;
  text-align: left;
  color: var(--header-bgd);
  font-weight: normal;
  font-style: italic;
  font-size: inherit;
}
.assets-header .balance-label {
  text-align: left;
  color: var(--header-bgd);
  font-size: smaller;
  font-weight: bold;
  margin: 0;
}
.assets-header .balance-txt {
  text-align: right;
}
.asset {
  flex: 1 1 25%;
  max-width: 400px;
  min-width: 250px;
}
</style>
