<template>
  <div class="coming-soon">
    <Sidebar class="sidebar" />
    <h1 class="cs-content">Coming Soon...</h1>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar";
export default {
  name: "ComingSoon",
  components: {
    Sidebar
  }
};
</script>

<style scoped>
.coming-soon {
  color: var(--title-text);
  display: flex;
}
.cs-content {
  margin: auto;
}
</style>
