<template>
  <header>
    <router-link to="/">
      <img src="@/assets/ac_logo_solid.png" class="logo" />
    </router-link>
    <!-- <p class="title no-translate">
      {{ title }}
    </p>
    <p class="subtitle">
      {{ subtitle }}
    </p> -->
  </header>
</template>

<script>
export default {
  name: "Header",
  props: {
    title: {
      type: String,
      default: "ARTCASA"
    },
    subtitle: {
      type: String,
      default: "- fine arts - digital and rare -"
    }
  }
};
</script>

<style scoped>
header {
  /* border-style: solid;
  border-color: var(--accent-color);
  border-width: 10px; */
  /* border-radius: 30px; */
  /* padding: 10px; */
  background-color: var(--header-bgd);
  margin-bottom: 1em;
  height: fit-content;
  text-align: left;
  /* width: fit-content; */
  /* box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}
.logo {
  height: 6em;
  margin-left: 0.75em;
  /* box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}
/* .title {
  display: inline;
  font-weight: 700;
  font-size: 2.25em;
}
.subtitle {
  display: flex;
  font-weight: 100;
  margin: auto;
} */
</style>
