<template>
  <div class="about">
    <Sidebar class="sidebar" />
    <div class="about-content">
      <div class="about-section">

        <h1>What is ArtCASA?</h1>

        <p>
          ArtCASA is a <b>gallery</b>, a <b>venue</b>, a <b>marketplace</b>, and a <b>community</b>.
          We are about supporting artists and facilitating the creation, preservation, and
          appreciation of rare digital art. We provide tools and services to help artists deploy
          limited-issue tokens, and provide access to a marketplace of buyers and patrons.
        </p>

      </div>
      <div class="about-section" id="deploy-service">

        <h1>Artwork-Token Deployment Services</h1>

        <p>
          ArtCASA shares the economy of our Lifetime Membership account status and the reduced
          transaction fees of issuing "sub-assets" to offer artists a low-cost way to deploy
          their artworks on the BitShares blockchain, and tokens issued through ArtCASA get
          automatic listing in the ArtCASA gallery, and participate in the shared economy of the
          ARTCASA token.
        </p>

        <h2>Built-in Marketplace:</h2>

        <p>
          The BitShares blockchain, upon which ArtCASA is built, has within it a robust and
          established market engine for exchange of tokens, providing the perfect venue to trade
          in digital art.
        </p>

        <h2>Sub-asset tokens:</h2>

        <p>
          "Sub-assets" of the ARTCASA token are assets that are issued by ArtCASA on behalf of
          artists and whose token symbol begins with "ARTCASA."  Example: An artist deploying a
          work entitled "Cool Art" can deploy, with our help, ARTCASA.COOLART, for far less
          costs than rolling their own top-level COOLART token, <em><b>and</b></em> their tokens
          will be automatically indexed and displayed in the ArtCASA gallery!
        </p>

        <h2>Connect with us:</h2>

        <p>
          To connect with a token-deployment liaison or to learn more about deploying tokenized
          artwork, join us in the <a href="https://t.me/artcasa.">ArtCASA Telegram Chat</a>.
        </p>

        <h2>Creator Resources:</h2>

        <ul>
          <li>
            <a href="https://github.com/Bit20-Creative-Group/BitShares-NFT-Specification">
              A Specification for Non-Fungible Tokens (NFTs) on the BitShares Blockchain
            </a>
          </li>
          <li>
            <a href="https://github.com/Bit20-Creative-Group/BitShares-NFT-Creator">
              BitShares NFT Creator (BNFTC)
            </a>
          </li>
        </ul>

      </div>
      <div class="about-section" id="artcasa-token">

        <h1>The ARTCASA Utility Token</h1>

        <p>
          ARTCASA is the utility token behind the ArtCASA Gallery.  Artwork tokens released
          under House ARTCASA are whitelisted to trade against this utility token.  This creates
          a common economic ecosystem backing all artworks issued through our gallery, to the
          benefit of holders of the ARTCASA token, not the least of which are the artists who
          have sold their works for ARTCASA tokens.  In a sense, this “lifts all boats” as our
          gallery grows.
        </p>

        <h2>Fixed Supply and Controlled Distribution:</h2>

        <p>
          The token supply of ARTCASA is capped at 1,000,000.<small>00000</small> ARTCASA, and
          distribution of the token is controlled by a liquidity pool (ARTCASA.BTSMM) through
          which the BitShares core token BTS can be exchanged for ARTCASA at a programmatically
          controlled, demand-weighted exchange rate.  The creators of ArtCASA deposited the
          initial liquidity into this pool to establish a minimum floor.
        </p>

        <h2>Exchange ARTCASA for Art!</h2>

        <p>
          One of the best reasons to acquire ARTCASA is to exchange it for art, supporting a
          vibrant community of artists and innovators.
        </p>

        <h2>Where to Get ARTCASA:</h2>

        <p>
          ARTCASA may be directly acquired through the following markets and liquidity pools
          which are actively maintained by the ArtCASA crew or its partners:
        </p>

        <table>
          <tr>
            <td>ARTCASA : BTS</td>
            <td>Liquidity Pool</td>
            <td>
              <a href="https://app.xbts.io/#/pool/ARTCASA.BTSMM">(xBTS)</a>,
              <a href="https://dex.iobanker.com/poolmart/liquidity-pools">(ioBanker)</a>
            </td>
          </tr>
          <tr>
            <td>ARTCASA : BTS</td>
            <td>DEX Market</td>
            <td>
              <a href="https://ex.xbts.io/market/ARTCASA_BTS">(xBTS)</a>,
              <a href="https://wallet.bitshares.org/#/market/ARTCASA_BTS">(BitShares.org)</a>,
              <a href="https://dex.iobanker.com/market/ARTCASA_BTS">(ioBanker)</a>
            </td>
          </tr>
          <tr>
            <td>BTWTY : ARTCASA</td>
            <td>Liquidity Pool</td>
            <td>
              <a href="https://app.xbts.io/#/pool/BTWTY.ARTCASAMM">(xBTS)</a>,
              <a href="https://dex.iobanker.com/poolmart/liquidity-pools">(ioBanker)</a>
            </td>
          </tr>
        </table>

        <h2>Connections through Pools:</h2>

        <p>
          ArtCASA is a spinoff product of the <b>Bit20 Creative Group</b>, known for its
          ecosystem of interconnected liquidity pools. By utilizing the pools in this ecosystem,
          ARTCASA may be exchanged for a number of other important and interesting assets,
          including useful gateway tokens, by puddle-jumping through chained pools:
        </p>
        <img class="figure" src="@/assets/pool_connections.png" style="width: 80%;">

        <p>
          A partial list of pools in the <a href="https://www.bittwenty.com">Bit20</a> ecosytem
          is here, showing some of the possible on-ramps to ARTCASA:
        </p>
        <table>
          <tr>
            <td>BTWTY : HONEST.USD</td>
            <td>Liquidity Pool</td>
            <td>
              <a href="https://app.xbts.io/#/pool/BTWTY.HUSDMM">(xBTS)</a>,
              <a href="https://dex.iobanker.com/poolmart/liquidity-pools">(ioBanker)</a>
            </td>
          </tr>
          <tr>
            <td>BTWTY : HONEST.BTC</td>
            <td>Liquidity Pool</td>
            <td>
              <a href="https://app.xbts.io/#/pool/BTWTY.HBTCMM">(xBTS)</a>,
              <a href="https://dex.iobanker.com/poolmart/liquidity-pools">(ioBanker)</a>
            </td>
          </tr>
          <tr>
            <td>BTWTY : XBTS.DOGE</td>
            <td>Liquidity Pool</td>
            <td>
              <a href="https://app.xbts.io/#/pool/BTWTY.DOGEXBTSX">(xBTS)</a>,
              <a href="https://dex.iobanker.com/poolmart/liquidity-pools">(ioBanker)</a>
            </td>
          </tr>
          <tr>
            <td>BTWTY : XBTS.USDC</td>
            <td>Liquidity Pool</td>
            <td>
              <a href="https://app.xbts.io/#/pool/BTWTY.XBTSXUSDC">(xBTS)</a>,
              <a href="https://dex.iobanker.com/poolmart/liquidity-pools">(ioBanker)</a>
            </td>
          </tr>
          <tr>
            <td>BTWTY : TWENTIX</td>
            <td>Liquidity Pool</td>
            <td>
              <a href="https://app.xbts.io/#/pool/BTWTY.TWENTIX">(xBTS)</a>,
              <a href="https://dex.iobanker.com/poolmart/liquidity-pools">(ioBanker)</a>
            </td>
          </tr>
        </table>
        <p></p>

      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
export default {
  name: "About",
  components: {
    Sidebar
  }
};
</script>

<style scoped>
@media (max-width: 768px) {
  .sidebar {
    display: none;
  }
}
.about {
  display: flex;
  width: 100%;
}
.about-content {
  background-color: rgb(104, 152, 64);
  color: var(--title-text);
  border-radius: 25px;
  min-width: 30ch;
  max-width: 800px;
  padding: 0.75em;
  margin-right: auto;
}
.about-section {
  /* background-color: rgb(240, 224, 252); */
  background-color: rgb(248, 240, 254);
  background-color: rgb(254, 244, 248);
  border-radius: 16px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 0.5em 1em;
  margin-bottom: 1em;
  font-family: "Open Sans", Helvetica, sans-serif;
}
.about-section a {
  font-weight: bold;
}
.about-section .figure {
  margin: 0 auto 1em auto;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.about-section p {
  text-align: left;
}
.about-section h2 {
  text-align: left;
}
.about-section h3 {
  text-align: left;
}
.about-section table {
  background-color: rgb(252, 168, 40);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin: 0 auto;
}
.about-section tr {
  background-color: rgba(255, 255, 255, 0.6);
}
.about-section td {
  padding-left: 1ch;
  padding-right: 1ch;
}
.about-section ul {
  text-align: left;
}
</style>
