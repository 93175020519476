<template>
  <div class="home">
    <Sidebar class="sidebar" />
    <div class="assets">
      <div v-for="(symbol, index) in house_symbols" v-bind:key="index">
        <AssetsSheet
          class="assets-trough"
          :assets='valid_assets[symbol]'
          :header='house_headers[symbol]'
          :header2='house_headers2[symbol]'
        />
      </div>
    </div>
  </div>
</template>

<script>
import AssetsSheet from "@/components/AssetsSheet";
import Sidebar from "@/components/Sidebar";
import { Config } from "@/components/config.js";
export default {
  name: "Home",
  components: {
    AssetsSheet,
    Sidebar
  },
  mounted() {
    this.connect();
  },
  data() {
    return {
      house_symbols: [],
      valid_assets: {},
      house_headers: {},
      house_headers2: {}
    };
  },
  methods: {
    async collect_assets(housename) {
      console.log("Looking for assets in house " + housename + "...");
      const BitShares = require("btsdex");
      await BitShares.connect(Config.api_node);
      var house = await BitShares.db.list_assets(housename, 50);
      // Narrow down to subassets of one base asset:
      house = house.filter(asset => asset["symbol"].includes(housename));
      console.log("Found " + house.length + " assets: ", house.map(a => a["symbol"]));
      try {
        var i = 0;
        for (i = 0; i < house.length; i++) {
          console.log("Checking " + house[i]["symbol"] + " for valid JSON description...");
          try {
            JSON.parse(house[i]["options"]["description"]);
          } catch (e) {
            console.log("Caught an e on asset '" + house[i]["symbol"] + "':");
            console.log(e);
            house.splice(i, 1);  // Remove item due to unparsable JSON,
            i--;                 // ...and decrement i, so we don't skip next item.
          }
        }
        // this narrows down to those with an nft_object in description
        var valid_assets_this_house = house.filter(
          asset => JSON.parse(asset["options"]["description"])["nft_object"]
        );
        console.log(
          "Returning " + valid_assets_this_house.length +
          " assets with 'nft_object' fields."
        );
        console.log(valid_assets_this_house.map(a => a["symbol"]));
        return valid_assets_this_house;
      } catch (e) {
        console.log("error: ", e);
      }
    },

    async connect() {
      for (const house_symbol_idx in Config.houses) {
        const house_symbol = Config.houses[house_symbol_idx]["token"];
        this.house_symbols.push(house_symbol);
        this.house_headers[house_symbol] = Config.houses[house_symbol_idx]["header"];
        this.house_headers2[house_symbol] = Config.houses[house_symbol_idx]["header2"];
      }
      for (const house_symbol_idx in Config.houses) {
        const house_symbol = Config.houses[house_symbol_idx]["token"];
        this.valid_assets[house_symbol] = await this.collect_assets(house_symbol);
      }
    }
  }
};
</script>

<style scoped>
@media (max-width: 768px) {
  .sidebar {
    display: none;
  }
}
.home {
  display: flex;
  /* flex-wrap: wrap; */
}
.assets {
  /* background-color: white; */
  flex: 1 1 80%;
}
.assets-trough {
  margin-bottom: 1.5em;
}
</style>
