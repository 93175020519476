<template>
  <div class="news">
    <Sidebar />
    <div class="news-broadsheet">
      <div class="news-story">
        <h1>News</h1>
      </div>
      <div class="news-story" id="ipfs-gateway">
        <h2>ArtCASA Launches Public IPFS Gateway</h2>
        <h3>October 4, 2021</h3>
        <p style="text-align: center">
          <em>
            ArtCASA launches
            <a href="https://ipfs.artcasa.gallery/ipfs/QmQPeNsJPyVWPFDVHb77w8G42Fvo15z4bG2X8D2GhfbSXc/readme">
            ipfs.artcasa.gallery</a> as a public service to the community and to support NFTs
            that use IPFS as back-end file storage.
          </em>
        </p>
        <p>
          IPFS is an ideal storage mechanism for large-format artworks for which on-chain
          storage would be prohibitive or inconvenient.  IPFS, or the <em><a href="https://ipfs.io">
          Inter-Planetary File System</a></em>, provides two important features for NFTs: content
          addressing, and the ability to locate data no matter who is hosting it, provided at
          least <em>someone</em> is
          hosting it.
        </p>
        <p>
          Content addressing means a stored file is identified by its cryptographic hash.  This
          hash is recorded on the blockchain in the NFT's object data, and used to request the
          file from the IPFS network.  After retrieving the file, the retriever can compute the
          hash themselves and verify that it matches the hash as recorded in the NFT object
          data, ensuring the correct, unaltered data has been returned. No "rug pull" or "bait
          and switch" is possible!  In addition, the ability to retrieve the file is robust so
          long as at least one node on the IPFS network is hosting the data.  (And popular data
          is often hosted by multiple nodes!)
        </p>
        <p>
          ArtCASA will "pin" IPFS-hosted NFT artworks on our IPFS gateway in order to make this
          data available to the entire IPFS network, and help guarantee persistent access.  Of
          course, the artworks will likely be hosted (pinned) by other parties as well,
          including possibly other NFT galleries, hobyists, archivists, and appreciators of fine
          digital art, assuring high-reliability storage and maximizing longevity.
        </p>
        <p>
          An IPFS gateway is an access point to content-addressed data. It will search the
          network for the requested data if it is not locally hosted, in order to return it.
          This means that the ArtCASA IPFS gateway can be used to retireve <em>any</em> data
          hosted on the network for which the hash identifier is known, not just ArtCASA hosted
          artworks.
          </p>
      </div>
      <div class="news-story" id="launch">
        <h2>Press Release | April 30th, 2021</h2>
        <h3>Bit20 Creative Group Launches ARTCASA to Make Rare Digital Art Minting Easy and Inexpensive for Artists</h3>
        <p>
          <em>Blockchain-based platform will simplify tools and enable cost-effective solutions
          for artists creating collectible rare digital art</em>
        </p>
        <p>
          <b>April 30, 2021</b> - The recent surge of digital art and collectibles released by
          artists is exciting, but most Blockchains are plagued by expensive fees, slow
          transactions, limited interfaces, and cumbersome tools.
        </p>
        <p>
          The Bit20 Creative Group are pleased to announce ARTCASA. ARTCASA is a gallery, a
          venue, a marketplace, and a community. Our mission is to support artists and to
          facilitate the creation, preservation, and appreciation of rare digital art. We
          provide tools and services to help artists deploy limited-issue tokens, and provide
          access to a marketplace of buyers and patrons.
        </p>
        <p>
          Artists make the art, and ARTCASA helps them get it tokenized. ARTCASA is born and
          bred on the BitShares Blockchain protocol and will utilize its native Decentralized
          Exchange (DEX). By leveraging the efficient BitShares ecosystem, cost savings and
          convenience are passed along to artists.
        </p>
        <p>
          <em>“Leveraging the Bitshares Blockchain allows the flexibility of an established,
          robust, enterprise-grade Blockchain without the high fees as seen with other popular
          Blockchains. The producers and curators of fine art are important players in this
          Cryptoart movement, so we are seeking to keep ARTCASA open and inclusive,”</em>
          comments John Conlin, head of Business Development at The Bit20 Creative Group and
          long-time Bitshares community builder.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar";
export default {
  components: {
    Sidebar
  }
};
</script>

<style scoped>
@media (max-width: 768px) {
  .sidebar {
    display: none;
  }
}
.news {
  display: flex;
  width: 100%;
}
.news-broadsheet {
  /* max-width: 70%; */
  background-color: rgb(104, 152, 64);
  color: var(--title-text);
  border-radius: 25px;
  min-width: 30ch;
  max-width: 800px;
  padding: 0.75em;
  margin-right: auto;
}
.news-story {
  background-color: rgb(254, 234, 202);
  border-radius: 16px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 0.5em 1em;
  margin-bottom: 1em;
  font-family: "Open Sans", Helvetica, sans-serif;
}
.news-story a {
  font-weight: bold;
}
.news-story p {
  text-align: left;
}
h1 {
  /*
  color: var(--title-text);
  border-radius: 33px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 0.5em;
  margin-bottom: 0.5em;
  */
}
</style>
