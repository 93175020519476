<template>
  <div class="sidebar">
    <router-link to="/">Home</router-link>
    <br />
    <br />
    <router-link to="/about">About ArtCASA</router-link>
    <br />
    <br />
    <router-link to="/news">News</router-link>
    <br />
    <br />
    <router-link to="/highlights">Highlights</router-link>
    <br />
    <br />
    <a href="https://twitter.com/artcasa_fine">Twitter</a>
    <br />
    <br />
    <a href="https://t.me/artcasa">Telegram Group</a>
    <br />
    <br />
    <router-link to="/preview">Publish Your Art</router-link>
    <br />
    <br />
    <router-link to="/licenses">Token Licenses</router-link>
    <!-- <br />
    <br />
    <a href="#">Interested in creating?</a>
    <br />
    <br />
    <a href="#">Contact Us</a> -->
  </div>
</template>

<script>
export default {
  name: "Sidebar"
};
</script>

<style scoped>
.sidebar {
  /* flex: 1 1 20%; */
  background-color: var(--header-bgd);
  color: var(--light-text);
  min-width: 20ch;
  max-width: 30ch;
  min-height: fit-content;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 30px;
  height: fit-content;
  padding: 0.75em;
  margin-right: 0.5em;
  /* border-style: solid;
  border-color: var(--accent-color);
  border-width: 10px; */
}
.sidebar a {
  /* text-decoration: none; */
  cursor: pointer;
  color: var(--light-text);
}
a.router-link-exact-active {
  color: var(--accent-color);
  font-weight: 700;
  text-decoration: none;
  cursor: default;
}
</style>
