import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import UserPage from "@/views/UserPage.vue";
import Preview from "@/views/Preview.vue";
import MessageSigner from "@/views/MessageSigner.vue";
import Nft from "@/views/Nft.vue";
import About from "@/views/About.vue";
import Licenses from "@/views/Licenses.vue";
import Highlights from "@/views/Highlights.vue";
import ComingSoon from "@/views/ComingSoon.vue";
import News from "@/views/News.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/nft/:nft_id",
    name: "Nft",
    component: Nft
  },
  {
    path: "/u/:user_id",
    name: "UserPage",
    component: UserPage
  },
  {
    path: "/preview",
    name: "Preview",
    component: Preview
  },
  {
    path: "/sign",
    name: "MessageSigner",
    component: MessageSigner
  },
  {
    path: "/about",
    name: "About",
    component: About
  },
  {
    path: "/licenses",
    name: "Licenses",
    component: Licenses
  },
  {
    path: "/highlights",
    name: "Highlights",
    component: Highlights
  },
  {
    path: "/coming-soon",
    name: "ComingSoon",
    component: ComingSoon
  },
  {
    path: "/news",
    name: "News",
    component: News
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
