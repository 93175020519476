<template>
  <footer>
    <router-link to="/about">About ArtCASA</router-link>
    |
    <a href="https://t.me/artcasa">Telegram Group</a>
    |
    <router-link to="/news">News</router-link>
    |
    <router-link to="/preview">Publish Your Art</router-link>
    <p class="copyright">{{ copyright }}</p>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  props: {
    copyright: {
      type: String,
      default: "Bit20 Creative Group | 2021"
    }
  }
};
</script>

<style scoped>
.copyright {
  font-weight: 100;
  /* border-radius: 30px;
  background-color: var(--text-bgd); */
  /* max-width: 30ch; */
  margin: auto;
}
footer {
  /* border-style: solid;
  border-width: 10px;
  border-color: var(--accent-color); */
  /* border-radius: 30px; */
  padding: 1em;
  /* background-color: var(--notext-bgd); */
  margin-top: 10px;
  /* box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  background-color: var(--header-bgd);
  margin-top: 1em;
  /* width: fit-content; */
}
a {
  cursor: pointer;
  color: var(--light-text);
}
a.router-link-exact-active {
  color: var(--accent-color);
  font-weight: 700;
  text-decoration: none;
  cursor: default;
}
</style>
