<template>
  <div class="asset">
    <div class="as-title">
      <h1>{{ title }}</h1>
    </div>
    <router-link :to="'/nft/' + nftAssetID" class="see-more-btn">
      <img v-if="media_url.length > 0" class="nftViewer" :src="media_url" />
      <img v-else class="nftViewer" :src="'data:image/png;base64,' + base64_img" />
    </router-link>
    <p class="artist">Artist: {{ artist }}</p>
    <router-link :to="'/nft/' + nftAssetID" class="see-more-btn">
      more details
    </router-link>
    <div style="width: 90%; margin: 1em auto;">
      <MarketWidget v-if="market"
                    :nftAssetObj="asset" :cashAssetSym="market"
                    :bid_limit="1" :ask_limit="1"
      />
    </div>
  </div>
</template>

<script>
import { parseNftObject } from "@/components/nftParse.js";
import MarketWidget from "./MarketWidget";
export default {
  name: "AssetCard",
  props: {
    asset: Object
  },
  components: {
    MarketWidget
  },
  created() {
    this.setup();
  },
  data() {
    return {
      title: "Title",
      artist: "Artist",
      base64_img: "",
      media_url: "",
      nftAssetID: "1.3.x",
      nftAsset: "SYMBOL",
      market: ""
    };
  },
  methods: {
    async setup() {
      const parsed = parseNftObject(this.asset);
      for (const key in parsed) {
        if (key in this) {
          this[key] = parsed[key];
        }
      }
    }
  }
};
</script>

<style scoped>
.nftViewer {
  max-width: 75%;
  min-width: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: bottom;
}
.asset {
  background-color: #DCDEDF;
  border-radius: 20px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  max-width: 100vw;
  padding-bottom: 1px;
}
.see-more-btn {
  display: block;
  color: var(--title-text);
  cursor: pointer;
  outline: none;
  margin-bottom: 1em;
}
.artist {
  color: var(--title-text);
  font-weight: 700;
  text-align: center;
  margin-top: 0.125em;
  margin-bottom: 0.125em;
}
h1 {
  margin-top: 0%;
  margin-bottom: 0.125em;
  color: var(--title-text);
}
</style>
