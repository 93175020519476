<template>
  <div class="news">
    <Sidebar />
    <div class="news-broadsheet">
      <div class="news-story">
        <h1>Highlights</h1>
      </div>
      <div class="news-story" id="ipfs-gateway">
        <h2>Coming Soon...</h2>
        <p>
          Coming soon... We'll highlight artists and interesting pieces here.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar";
export default {
  components: {
    Sidebar
  }
};
</script>

<style scoped>
@media (max-width: 768px) {
  .sidebar {
    display: none;
  }
}
.news {
  display: flex;
  width: 100%;
}
.news-broadsheet {
  /* max-width: 70%; */
  background-color: rgb(104, 152, 64);
  color: var(--title-text);
  border-radius: 25px;
  min-width: 30ch;
  max-width: 800px;
  padding: 0.75em;
  margin-right: auto;
}
.news-story {
  background-color: rgb(254, 234, 202);
  border-radius: 16px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 0.5em 1em;
  margin-bottom: 1em;
  font-family: "Open Sans", Helvetica, sans-serif;
}
.news-story a {
  font-weight: bold;
}
.news-story p {
  text-align: left;
}
h1 {
  /*
  color: var(--title-text);
  border-radius: 33px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 0.5em;
  margin-bottom: 0.5em;
  */
}
</style>
