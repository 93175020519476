<template>
  <div class="nft">
    <Sidebar class="sidebar" />
    <FullAsset :asset="$route.params.nft_id" />
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar";
import FullAsset from "@/components/FullAsset";
export default {
  name: "Nft",
  components: {
    Sidebar,
    FullAsset
  }
};
</script>

<style scoped>
@media (max-width: 768px) {
  .sidebar {
    display: none;
  }
}
.nft {
  display: flex;
  width: 100%;
}
</style>
