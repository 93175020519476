<template>
  <div class="preview">
    <Sidebar class="sidebar" />
    <div class="preview-broadsheet">
      <div class="preview-content">
        <h1>How to Tokenize with ArtCASA</h1>
        <p>
          <b>More details coming soon...</b> but the first step is to join us in the ArtCASA room on
          Telegram at <a href="https://t.me/artcasa">t.me/artcasa</a> and we'll connect you with
          an artwork-deployment liaison who can help guide you through the process.
        </p>
      </div>
      <div class="preview-content">
        <h2>Creator Resources:</h2>
        <ul>
          <li>
            <a href="https://github.com/Bit20-Creative-Group/BitShares-NFT-Specification">
              A Specification for Non-Fungible Tokens (NFTs) on the BitShares Blockchain
            </a>
          </li>
          <li>
            <a href="https://github.com/Bit20-Creative-Group/BitShares-NFT-Creator">
              BitShares NFT Creator (BNFTC)
            </a>
          </li>
        </ul>
      </div>
      <div class="preview-content">
        <h1>Preview NFT</h1>
        <p>
          Place the JSON blob titled <code>[nft_name]_object.json</code> or
          <code>[nft_name]_final.json</code> into the text box below.
          Our site will dislay a preview the JSON blob based on the
          <a
            href="https://github.com/Bit20-Creative-Group/BitShares-NFT-Specification"
          >NFT Specification outlined here</a
          >.
        </p>
        <textarea id="json_blob" class="ta" />
        <button @click="preview_click()" class="p-button">Preview</button>
      </div>
      <div class="preview-content" v-if='preview_asset !== null'>
        <AssetsSheet
          :assets='[preview_asset]'
          :header='"Ex libris House ARTCASA: (Preview)"'
          :header2='"When rendered in card view, your asset will appear thusly:"'
        />
      </div>
      <div class="preview-content" v-if='preview_asset !== null'>
        <AssetDetail
          :asset='preview_asset'
          :asset_ddo='{current_supply: 0}'
          :holders='[]'
        />
      </div>
    </div>
  </div>
</template>

<script>
import AssetsSheet from "@/components/AssetsSheet";
import AssetDetail from "@/components/AssetDetail";
import Sidebar from "@/components/Sidebar";
export default {
  name: "Preview",
  components: {
    AssetsSheet,
    AssetDetail,
    Sidebar
  },
  data() {
    return {
      preview_asset: null,
      preview_asset_id: 281474976710655,
    };
  },
  methods: {
    asset_from_nft_object(nft_object) {
      console.log("ignore ", nft_object)
      return {
        id: "1.3." + this.preview_asset_id,
        issuer: "1.2.1799854",
        symbol: "ARTCASA.TOKEN",
        precision: 1,
        options: {
          max_supply: 1,
          description: JSON.stringify({
            main: "This asset has not yet been created.",
            short_name: "Preview",
            market: "ARTCASA",
            nft_object: nft_object,
            nft_signature: "N/A"
          })
        }
      };
    },
    reset_data: function() {
      this.preview_asset = null;
      this.preview_asset_id--;
    },
    preview_click: function() {
      this.reset_data();
      this.$nextTick(() => {
        var json_string = document.getElementById("json_blob").value;
        var json_blob = JSON.parse(json_string);
        this.preview_asset = this.asset_from_nft_object(json_blob);
      });
    }
  }
};
</script>

<style scoped>
@media (max-width: 768px) {
  .sidebar {
    display: none;
  }
}
.preview {
  display: flex;
  width: 100%;
}
.ta {
  min-width: 90%;
  max-width: 90%;
  min-height: 15em;
  margin-left: 0.25em;
  margin-right: 0.25em;
}
.preview-broadsheet {
  background-color: rgb(104, 152, 64);
  color: var(--title-text);
  border-radius: 25px;
  min-width: 30ch;
  max-width: 800px;
  padding: 0.75em;
  margin-right: auto;
}
.preview-content {
  background-color: rgb(248, 240, 254);
  border-radius: 16px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 0.5em 1em;
  margin-bottom: 1em;
  font-family: "Open Sans", Helvetica, sans-serif;
}
.p-button {
  background-color: var(--btn-bgd);
  color: white;
  padding: 1em 2em;
  cursor: pointer;
  border-radius: 30px;
  outline: none;
  font-weight: 900;
  margin-bottom: 1em;
}
a {
  color: var(--accent-color);
}
h1 {
  color: var(--title-text);
}
h1 {
  margin-top: 0%;
  border-style: solid;
  border-width: 3px;
  border-color: transparent;
  border-radius: 10px;
  color: var(--title-text);
}
p {
  color: var(--title-text);
  margin: 0.125em;
}
</style>
